import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { NavigateButton } from 'src/components/buttons/navigatebutton';
import { Carousel } from 'src/components/carousel/carousel';
import { Carousel1 } from 'src/components/carousel/carousel1';
import { Carousel2 } from 'src/components/carousel/carousel2';
import { Carousel3 } from 'src/components/carousel/carousel3';
import { Slider } from 'src/components/carousel/Slider';
import { Slider1 } from 'src/components/carousel/Slider1';
import { Slider2 } from 'src/components/carousel/Slider2';
import { Slider3 } from 'src/components/carousel/Slider3';
import { Slider4 } from 'src/components/carousel/Slider4';
import { Slider5 } from 'src/components/carousel/Slider5';
import { Modal } from 'src/components/Forms/Modal';
import { BgLogo } from 'src/components/logo/Bglogo';
import { ROUTES } from 'src/config/Routes/Routes';

export const HomeContent = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showslider, setShowSlider] = useState<string | null>(null);

  const sections = [0, 1, 2, 3]; // Assuming you have 4 sections to cycle through
  const delay = 3000; // Slide transition delay in milliseconds
  const navigate = useNavigate();

  const clickImage = (sliderType: string) => {
    setShowSlider(sliderType);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === sections.length - 1 ? 0 : prevIndex + 1
      );
    }, delay);

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [sections.length]);

  return (
    <>
      <div className="positive">
        <div className="p-4 relative">
          <div className="bg">
            <div className="grid grid-cols-2 h-[400px] sm:h-[500px] md:h-[668px]">
              {/* First Section with Image and Text */}
              <div className="col-span-2 md:col-span-1 flex items-center justify-center z-20">
                <BgLogo />
              </div>
            </div>
          </div>
        </div>
        {/* Section 2 */}
        <div className="relative sec2 mt-8 overflow-hidden">
          <div className="grid grid-cols-1 sm:grid-cols-12 gap-4">
            {/* Left Section */}
            <div className="sm:col-span-6 people h-40 sm:h-auto bg-gray-200 "></div>

            {/* Right Section */}
            <div className="sm:col-span-6">
              <div className="p-6 section2">
                <p className="text-[#2D610F] text-lg sm:text-xl font-semibold reveal-text">
                  Get to Know
                </p>
                <p className="text-sm sm:text-base md:text-lg p-4 text-justify">
                  Nature and People is a social media platform managed by the
                  Society of Filipino Foresters, Inc.-Bislig Chapter (SFFI) that
                  serves as an avenue for raising environmental awareness,
                  eliciting support, and inspiring individuals to exemplify
                  environmental stewardship and promoting a sense of shared
                  social responsibility for creating a wholesome environment.
                </p>
                <div className="p-4">
                  <NavigateButton
                    label="More"
                    onClick={() => navigate(`/${ROUTES.About}`)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*sec2.1 */}
        {/* Section 4 */}
        <div className=" relative">
          <div className="mx-auto">
            <div className="mx-auto p-4 text-justify">
              <label className="text-[#2D610F] flex justify-center text-xl sm:text-3xl p-2 font-bold">
                Activities
              </label>
            </div>
          </div>
          <div className="act relative">
            <div className=" sec3 grid grid-cols-6 p-8 w-[90%] md:w-[80%] mx-auto relative  ">
              <div className="col-span-6 md:col-span-2 p-2">
                <div className="border-[1px] border-[#f7c200] relative mb-4 bg-[url('./image/planning.jpg')] bg-cover bg-center h-[250px] sm:h-[300px] md:h-[350px] hover:scale-105 transition-transform duration-300">
                  <div
                    className="w-full h-full col1 stroke-black"
                    onClick={() => {
                      clickImage('treePlanting');
                    }}
                  >
                    <p className="hover:shadow-xl hover:shadow-[#C0DF00] transition-shadow duration-300 text-white C0DF00 tracking-widest flex justify-center font-bold text-md items-center w-full h-full relative">
                      TREE PLANTING
                    </p>
                  </div>
                </div>
                <div className="border-[1px] border-[#f7c200] relative mb-4 bg-[url('./image/col3.png')] bg-cover bg-center h-[250px] sm:h-[300px] md:h-[350px] hover:scale-105 transition-transform duration-300">
                  <div
                    onClick={() => {
                      clickImage('academic');
                    }}
                    className="w-full h-full col1 stroke-black"
                  >
                    <p className="hover:shadow-xl hover:shadow-[#C0DF00] transition-shadow duration-300 text-white flex tracking-widest  justify-center font-bold text-md items-center w-full h-full relative">
                      ACADEMIC ACTIVITIES
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-6 md:col-span-2 p-2">
                <div className="border-[1px] hover:scale-105 transition-transform duration-300 border-[#f7c200] relative mb-4 bg-[url('./image/camping.jpg')] bg-cover bg-center h-[250px] sm:h-[300px] md:h-[350px]">
                  <div
                    onClick={() => {
                      clickImage('camping');
                    }}
                    className="w-full h-full col1 stroke-black  col1"
                  >
                    <p className="hover:shadow-xl hover:shadow-[#C0DF00] transition-shadow duration-300 text-white tracking-widest flex justify-center font-bold text-md items-center w-full h-full relative">
                      CAMPING
                    </p>
                  </div>
                </div>
                <div className=" border-[1px] hover:scale-105 transition-transform duration-300 border-[#f7c200] relative mb-4  bg-[url('./image/benchmarking.jpg')] bg-cover bg-center h-[250px] sm:h-[300px] md:h-[350px]">
                  <div
                    onClick={() => {
                      clickImage('benchmarking');
                    }}
                    className="w-full h-full col1 stroke-black  col1"
                  >
                    <p className="hover:shadow-xl hover:shadow-[#C0DF00] transition-shadow duration-300 text-white tracking-widest flex justify-center font-bold text-md items-center w-full h-full relative">
                      BENCHMARKING
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-6 md:col-span-2 p-2">
                <div className="border-[1px] hover:scale-105 transition-transform duration-300 border-[#f7c200] relative mb-4 bg-[url('./image/col2.png')]  bg-cover bg-center h-[250px] sm:h-[300px] md:h-[350px]">
                  <div
                    onClick={() => {
                      clickImage('drive');
                    }}
                    className="w-full h-full col1 stroke-black  col1"
                  >
                    <p className="hover:shadow-xl hover:shadow-[#C0DF00] transition-shadow duration-300 text-white tracking-widest flex justify-center font-bold text-md items-center w-full h-full relative">
                      INFORMATION DRIVE
                    </p>
                  </div>
                </div>
                <div className="border-[1px] hover:scale-105 transition-transform duration-300 border-[#f7c200] relative mb-4 bg-[url('./image/research.jpg')] bg-cover bg-center h-[250px] sm:h-[300px] md:h-[350px]">
                  <div
                    onClick={() => {
                      clickImage('laboratories');
                    }}
                    className="w-full h-full col1 stroke-black  col1"
                  >
                    <p className="hover:shadow-xl hover:shadow-[#C0DF00] transition-shadow duration-300 text-white tracking-widest flex justify-center font-bold text-md items-center w-full h-full relative">
                      LABORATORY SITES
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto p-4 text-justify">
              <label className="text-[#2D610F] flex justify-center text-xl sm:text-3xl p-4 font-bold">
                <NavigateButton
                  label="More"
                  onClick={() => navigate(`/${ROUTES.Activites}`)}
                />
              </label>
            </div>
          </div>

          {showslider === 'treePlanting' && (
            <Modal show={true}>
              <Slider onCloseSlider={() => setShowSlider(null)} />
            </Modal>
          )}
          {showslider === 'academic' && (
            <Modal show={true}>
              <Slider1 onCloseSlider={() => setShowSlider(null)} />
            </Modal>
          )}
          {showslider === 'camping' && (
            <Modal show={true}>
              <Slider2 onCloseSlider={() => setShowSlider(null)} />
            </Modal>
          )}
          {showslider === 'benchmarking' && (
            <Modal show={true}>
              <Slider3 onCloseSlider={() => setShowSlider(null)} />
            </Modal>
          )}
          {showslider === 'drive' && (
            <Modal show={true}>
              <Slider4 onCloseSlider={() => setShowSlider(null)} />
            </Modal>
          )}
          {showslider === 'laboratories' && (
            <Modal show={true}>
              <Slider5 onCloseSlider={() => setShowSlider(null)} />
            </Modal>
          )}
        </div>

        {/* Section 3 */}
        <div className="relative sec3 p-6 border-t-[#C0DF00] bg-[#F1F1F1] border rounded-md">
          <div className="mx-auto p-4 text-justify">
            <label className="text-[#2D610F] title flex justify-center text-xl sm:text-3xl p-4 font-bold">
              Arboretum Cum Rest & Recreation Area of Bislig City
            </label>
            <p className="flex justify-center p-4 text-sm">
              Originally, the Arboretum area encompassed an estimated 4 hectares
              of forest land that formed part of the Integrated Forest
              Management Agreement (IFMA) 35 area of the PICOP Resources Inc.
              that expired in 2007 and was subsequently increased to 8 hectares
              through Barangay Resolution No.___ of San Roque, Bislig City. It
              is located at the Junction of Road 1 and 2 of Sitio Sanyata,
              Barangay San Roque, Bislig City, 14 kilometers distant from
              Barangay Mangagoy, and is accessible by any means of land
              transportation. After the IFMA expiration, the management and
              protection of the area was assumed by DENR PENRO-Surigao del Sur.
            </p>
            <p className="flex justify-center p-4 text-sm">
              In 2015, the portion of the expired IFMA No. 35 was identified as
              the Arboretum cum Ecotourism Site duly implemented by the DENR
              PENRO-Surigao del Sur that inaugurated site assessment, inventory
              of species, enrichment planting, monitoring, and maintenance
              protection. In 2016, the management of the Arboretum was
              transferred to DENR CENRO-Bislig, yet due to financial
              constraints, the area was left unmaintained.
            </p>
            <p className="flex justify-center p-4 text-sm">
              In 2022, upon the designation of the incumbent CENRO Nathaniel E.
              Racho, the plans for the development of the now-christened
              Arboretum cum Rest and Recreation Area of Bislig City commenced in
              formidable partnership with the stakeholders. Further backed up by
              the issuance of Regional Special Order No. 493 of 2022,
              “Designating Portion of Public Land Located at Sitio Sanyata, San
              Roque, Bislig City, Surigao del Sur as the ‘Arboretum cum Rest and
              Recreation Area of Bislig City’.”
            </p>
            <p className="flex justify-center p-4 text-sm">
              In 2024, the Arboretum cum Rest and Recreation Area of Bislig City
              (ARRABiC for brevity) is now home to more than twenty-six (26)
              tree species, dominated by indigenous species and twenty-one (21)
              faunal species. With its full-site functionality prospered by the
              Society of Filipino Foresters Inc.-Bislig Chapter (SFFI) whose
              members are majority coming from DENR CENRO-Bislig, it has hosted
              several worthwhile environmental protection and conservation
              activities and other-related events that enjoined different
              agencies, groups of individuals, and companies.
            </p>
            <p className="flex justify-center p-4 text-sm">
              On August 2, 2024, the inauguration of ARRABiC was formalized and
              complemented led by Assistant Secretary for Field
              Operations-Western Mindanao and FMB Director, Arleigh J. Adorable,
              CESO III, OIC, DENR Regional Executive Director of Caraga,
              Maritess M. Ocampo, OIC, PENRO of Surigao del Sur, Adelfo R.
              Luengas, Jr., and OIC, CENRO of Bislig, Nathaniel E. Racho with
              the presence of Honorable Victor B. Alvar, City ENR Officer Daniel
              Jabagat, and Hon. Mario Angelo Rabuya, Barangay Captain of San
              Roque, SFFI-Bislig Chapter members and CENRO-Bislig personnel.
            </p>

            <p className="flex justify-center p-4 text-sm">
              The presence of great partnerships with the stakeholders
              flourished over time and ceaseless campaigns for sustainable
              environmental protection and conservation molded ARRABiC to what
              it is now with aspirations of national and international
              recognition.
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="mx-auto p-4">
              <label className="font-semibold text-2xl p-4 flex justify-center bg-[#C0DF00] w-full">
                Vision
              </label>
              <p className="px-4 pb-4 pt-4 text-justify text-sm">
                Arboretum cum Rest and Recreation Area of Bislig City as a
                vibrant eco-destination site by 2030, showcasing Forest and
                Biodiversity Conservation through social mobilization with a
                thriving nature-friendly community enterprise, jointly managed
                by the City and Barangay Local Government Units, DENR
                CENRO-Bislig, and Society of Filipino Foresters, Inc.-Bislig
                Chapter.
              </p>
            </div>
            <div className="mx-auto p-4">
              <label className="font-semibold text-2xl p-4 flex justify-center bg-[#C0DF00] w-full">
                Mission
              </label>
              <p className="px-4 pb-4 pt-4 text-justify text-sm">
                To actively engage all stakeholders especially the youth for the
                tree-growing initiatives and for protecting and conserving the
                biodiversity therein for a sustainable nature-based tourism.
              </p>
            </div>
            <div className="mx-auto p-4">
              <label className="font-semibold text-2xl p-4 flex justify-center bg-[#C0DF00] w-full">
                Goals
              </label>
              <p className="px-4 pb-4 pt-4 text-justify text-sm">
                <span className="font-bold">A</span> – ctive cooperation and
                engagement <br />
                <span className="font-bold">R</span> – ealized Vision by 2030{' '}
                <br />
                <span className="font-bold">R</span> – rediscover the Beauty of
                Nature <br />
                <span className="font-bold">A</span> – chieve Sustainable
                Ecotourism <br />
                <span className="font-bold">Bi</span> – g impact on Local
                Economic Growth and Stability <br />
                <span className="font-bold">C</span> – ommune with Nature
              </p>
            </div>
          </div>
        </div>
        {/* Section 4 */}
        {/* Section 5 */}
        <div className="sec5 relative">
          <div className="w-[90%] md:w-[80%] mx-auto">
            <div className="grid grid-cols-8">
              <div className="col-span-6 md:col-span-4 p-4"></div>
              <div className="col-span-6 md:col-span-4 p-4">
                <div className="float-right">
                  <NavigateButton
                    label="More"
                    onClick={() => navigate(`/${ROUTES.Seedlings}`)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="relative w-full max-w-7xl mx-auto overflow-hidden">
            <div
              className="flex"
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
                transition: 'transform 0.5s ease-in-out',
              }}
            >
              <Carousel />
              <Carousel1 />
              <Carousel2 />
              <Carousel3 />
            </div>
          </div>
        </div>
        {/* Section 6 */}
        <div className="sec6 relative">
          <div>
            <p className="text-[#2D610F] flex justify-center text-2xl sm:text-3xl p-4 font-bold">
              Interested in Planting Trees?
            </p>
            <p className="text-[#2D610F] flex justify-center font-light text-sm sm:text-base p-4">
              ROLL UP YOUR SLEEVES AND GET YOUR HANDS DIRTY...
            </p>
          </div>
          <div className="flex justify-center">
            <button
              className="p-4 rounded-xl text bg-[#93CC36] text-black text-sm flex"
              onClick={() => navigate(`/${ROUTES.Contact}`)}
            >
              Donate now
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
