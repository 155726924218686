export const Carousel: React.FC = () => {
  return (
    <>
      <div className="w-full flex-shrink-0">
        <div className="grid grid-cols-8 p-8 w-[80%] mx-auto positive gap-2">
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="seed3"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Dao
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Dracontomelon Dao</p>
              </div>
            </div>
          </div>
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="seed2 rounded:md"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Batikuling
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Litsea leytensis</p>
              </div>
            </div>
          </div>
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="seed1"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Bangulo
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Nauclea orientalis</p>
              </div>
            </div>
          </div>
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="seed4"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Tangisang-bayawak
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Ficus variegata</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
