import { useEffect, useState } from 'react';

import { Carousel } from 'src/components/carousel/carousel';
import { Carousel1 } from 'src/components/carousel/carousel1';
import { Carousel2 } from 'src/components/carousel/carousel2';
import { Carousel3 } from 'src/components/carousel/carousel3';
import { Carousel4 } from 'src/components/carousel/carousel4';
import { Carousel5 } from 'src/components/carousel/carousel5';
import { Carousel6 } from 'src/components/carousel/carousel6';
import { BgLogo } from 'src/components/logo/Bglogo';

export const Seedlings = () => {
  const [showseeds, setShowSedds] = useState<boolean>(false);

  const seedreveal = () => {
    setShowSedds((show) => !show);
  };

  useEffect(() => {
    seedreveal;
  }, []);
  return (
    <>
      <div className="p-4 relative">
        <div className="seedlings">
          <div className="grid grid-cols-2 h-[668px]">
            <div className="col-span-2 md:col-span-1 flex items-center justify-center z-20">
              <BgLogo />
            </div>

            <div className="col-span-2 md:col-span-1 flex items-center justify-center p-6 text-white text-sm z-10">
              <p className="reveal-text">
                <span className="font-bold text-2xl text-white">Seedlings</span>
                <br />
                <br />
                The simple act of planting a tree has the power to change the
                world. It is a reminder that even the smallest gestures can have
                a lasting impact. As the tree grows, so too does our hope, our
                connection to nature, and our understanding of the importance of
                nurturing the environment. By planting a tree, we sow the seeds
                of a brighter, greener future. The simple act of planting a tree
                has the power to change the world. It is a reminder that even
                the smallest gestures can have a lasting impact. As the tree
                grows, so too does our hope, our connection to nature, and our
                understanding of the importance of nurturing the environment. By
                planting a tree, we sow the seeds of a brighter, greener future.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="relative w-full max-w-7xl mx-auto overflow-hidden">
          <div className="flex flex-wrap justify-center">
            <Carousel />
            <Carousel1 />
            <Carousel2 />
            <Carousel3 />
            {showseeds && (
              <div className="relative w-full max-w-7xl mx-auto overflow-hidden">
                <div className="flex flex-wrap justify-center">
                  <Carousel4 />
                  <Carousel5 />
                  <Carousel6 />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-center relative">
          <button
            className="bg-[#93CC36] rounded-xl text-sm p-2"
            onClick={() => {
              seedreveal();
            }}
          >
            LOAD MORE
          </button>
        </div>
      </div>
    </>
  );
};
