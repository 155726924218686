export const Carousel2: React.FC = () => {
  return (
    <>
      <div className="w-full flex-shrink-0">
        <div className="grid grid-cols-8 p-8 w-[80%] mx-auto positive gap-2">
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="bg-[url('./image/lapnisan.jpeg')] bg-cover bg-center bg-no-repeat h-[200px] w-full rounded-tl-[30px] rounded-tr-[30px]"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Lapnisan
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                Vitex parviflora
              </div>
            </div>
          </div>
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="bg-[url('./image/kalipapa.jpeg')] bg-cover bg-center bg-no-repeat h-[200px] w-full rounded-tl-[30px] rou rounded:md"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Kalipapa
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Garcinia mangostana</p>
              </div>
            </div>
          </div>
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="bg-[url('./image/bani.jpeg')] bg-cover bg-center bg-no-repeat h-[200px] w-full rounded-tl-[30px] rou"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Bani
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Gliricidia sepium</p>
              </div>
            </div>
          </div>
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="bg-[url('./image/panguringon.jpeg')] bg-cover bg-center bg-no-repeat h-[200px] w-full rounded-tl-[30px] rou"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Panguringon
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Tectona grandis</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
