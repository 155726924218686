import { BgLogo } from 'src/components/logo/Bglogo';

export const Activities = () => {
  return (
    <>
      <div>
        <div className="p-4 relative">
          <div className="activities1">
            <div className="grid grid-cols-2 h-[668px] sm:h-[500px] md:h-[668px]">
              <div className="col-span-2 md:col-span-1 flex items-center justify-center z-20">
                <BgLogo />
              </div>

              <div className="col-span-2 md:col-span-1 flex items-center justify-center p-6 text-white text-sm z-10">
                <p className="reveal-text">
                  <span className="font-bold text-xl sm:text-2xl text-white">
                    Activities
                  </span>
                  <br />
                  <br />
                  The simple act of planting a tree has the power to change the
                  world. It is a reminder that even the smallest gestures can
                  have a lasting impact. As the tree grows, so too does our
                  hope, our connection to nature, and our understanding of the
                  importance of nurturing the environment. By planting a tree,
                  we sow the seeds of a brighter, greener future.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="actsc2 relative">
          <div className="grid grid-cols-6 p-6 md:p-12 w-full md:w-[80%] mx-auto">
            <div className="col-span-6 md:col-span-3 p-4">
              <div className="bg-[url('./image/moncayo.jpg')] bg-cover bg-center h-[250px] sm:h-[300px] md:h-[350px] rounded-xl"></div>
            </div>

            <div className="col-span-6 md:col-span-3">
              <div className="p-4">
                <p className="text-sm text-justify font-light">
                  <span className="font-bold text-xl sm:text-2xl text-[#2D610F]">
                    Benchmarking
                  </span>
                  <br />
                  <br />
                  The Local Government Unit of Monkayo, Municipal Environment &
                  Natural Resources Office (MENRO) had conducted a benchmarking
                  visit at Arboretum Cum Rest & Recreation Area at Sitio
                  Sanyata, Barangay San Roque, Bislig City.
                  <br />
                  <br />
                  As part of their continuous efforts to enhance their
                  initiatives and learn from exemplary models from other field
                  offices, they have identified the Arboretum as premier
                  institution that showcase biodiversity conservation,
                  environmental education and environmental stewardship.
                </p>
              </div>
            </div>

            <div className="col-span-6 md:col-span-3 p-4">
              <div className="p-4">
                <p className="text-sm text-justify font-light">
                  <span className="font-bold text-xl sm:text-2xl text-[#2D610F]">
                    Inauguration
                  </span>
                  <br />
                  <br />
                  The DENR has inaugurated its 8.4-hectare Arboretum and Rest
                  Recreation Area in Brgy. San Roque, Bislig City, Surigao del
                  Sur, marking a significant advancement in biodiversity
                  conservation, research, and sustainable tourism.
                  <br />
                  <br /> The arboretum serves as a hub for education and
                  research and plays a crucial role in carbon sequestration,
                  with a capacity to absorb up to 35,000 megatons of carbon per
                  hectare. It also offers significant economic potential for
                  forest carbon projects, forest cover expansion, and
                  sustainable income generation.
                  <br />
                  <br />
                  Home to critically endangered species, including a
                  165-year-old Bagtikan tree, the arboretum sets a benchmark for
                  sustainable tourism and conservation. The project is developed
                  in partnership with local government units, academia, and
                  community stakeholders.
                </p>
              </div>
            </div>

            <div className="col-span-6 md:col-span-3">
              <div className="bg-[url('./image/inaguration.jpg')] bg-cover bg-center h-[250px] sm:h-[300px] md:h-[350px] rounded-xl"></div>
            </div>

            <div className="col-span-6 md:col-span-3 p-4">
              <div className="bg-[url('./image/lgu.jpg')] bg-cover bg-center h-[250px] sm:h-[300px] md:h-[350px] rounded-xl"></div>
            </div>

            <div className="col-span-6 md:col-span-3">
              <div>
                <p className="text-sm text-justify p-4 font-light">
                  <span className="font-bold text-xl sm:text-2xl text-[#2D610F]">
                    Oplan Broadcastreeing Project
                  </span>
                  <br />
                  <br />
                  The Oplan Broadcastreeing Project is a collaborative effort
                  involving KBP chapters, government agencies, non-governmental
                  organizations, and volunteers in the city that came together
                  to plant at the Arboretum using a variety of tree species such
                  as Tanguile, Lanutan, Narig, Sagimsim, Lipote, and Lauan. It
                  aims to promote environmental conservation and help withstand
                  climate change. It helps restore ecosystems, prevent erosion,
                  and create habitats for wildlife while raising awareness about
                  environmental issues. Also, encourage community involvement in
                  advocating for a greener environment for future generations
                  and the world at large.
                </p>
              </div>
            </div>

            <div className="col-span-6 md:col-span-3 p-4">
              <div>
                <p className="text-sm text-justify p-4 font-light">
                  <span className="font-bold text-xl sm:text-2xl text-[#2D610F]">
                    Plant a tree on your special day
                  </span>
                  <br />
                  <br />
                  Plant a tree on your special day aims in promoting
                  environmental sustainability and conservation through tree
                  planting initiatives.
                  <br />
                  <br />
                  With every special days celebrated, we encourage people to
                  plant a tree and celebrate your special days in an
                  eco-friendly way. Yes, I am talking about “PLANT A TREE ON
                  YOUR SPECIAL DAY”.
                  <br />
                  <br />
                  The Arboretum Cum Rest & Recreation Area also facilitates the
                  green celebration on special days like birthdays,
                  anniversaries and other life celebrations. This is one of the
                  most efficient ways to combat climate change and global
                  warming.
                  <br />
                  <br />
                  Change has to begin from us, so whoever reading this, make it
                  a practice to plant a tree till 7 billion people of this world
                  have at least plant a tree to build a greener future
                </p>
              </div>
            </div>

            <div className="col-span-6 md:col-span-3">
              <div className="bg-[url('./image/bday.jpg')] bg-cover bg-center h-[250px] sm:h-[300px] md:h-[350px] rounded-xl"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
