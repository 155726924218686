import { Outlet } from 'react-router-dom';

// import { HomeContent } from './pages/body/HomeContent';

import { Bodycontent } from './pages/body/BodyConte';
import { Footer } from './pages/footer/Footer';
import { Header } from './pages/header/Header';

const App: React.FC = () => {
  return (
    <>
      <Header />
      <Bodycontent>
        <Outlet />
      </Bodycontent>
      {/* <HomeContent /> */}
      {/* <AboutContent /> */}
      <Footer />
    </>
  );
};

export default App;
