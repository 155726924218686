import { createHashRouter } from 'react-router-dom';

import App from './App';
import { AboutContent } from './pages/body/AboutContent';
import { Activities } from './pages/body/Activities';
import { Contacts } from './pages/body/Contacts';
import { HomeContent } from './pages/body/HomeContent';
import { Seedlings } from './pages/body/Seedlings';

export const AppRouter = createHashRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { index: true, element: <HomeContent /> },
      { path: 'home', element: <HomeContent /> },
      { path: 'about', element: <AboutContent /> },
      { path: 'activities', element: <Activities /> },
      { path: 'seedlings', element: <Seedlings /> },
      { path: 'contact', element: <Contacts /> },
    ],
  },
]);
