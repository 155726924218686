import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { HandleClicker } from 'src/components/HandleClick';
import { HeaderLogo } from 'src/components/logo/Headerlogo';
import { ROUTES } from 'src/config/Routes/Routes';

export const Header = () => {
  const [activeMenu, setActiveMenu] = useState<string | null>('home');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation(); // Get the current location

  // Function to handle menu click and update active menu
  const handleMenuClick = (menu: string) => {
    setActiveMenu(menu);
    localStorage.setItem('activeMenu', menu);
    setIsMenuOpen(false); // Close menu on item click
  };

  // Function to determine the active menu based on the route
  const activeMenuClass = (menu: string) =>
    activeMenu === menu
      ? 'bg-[#397714] text-white rounded-xl p-2 mr-2' // Active menu color
      : 'text-[#27540C] p-2 mr-2'; // Non-active menu color

  // Set active menu based on the current route in the URL
  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes(ROUTES.Home)) {
      setActiveMenu('home');
    } else if (currentPath.includes(ROUTES.About)) {
      setActiveMenu('about');
    } else if (currentPath.includes(ROUTES.Activites)) {
      setActiveMenu('activities');
    } else if (currentPath.includes(ROUTES.Seedlings)) {
      setActiveMenu('seedlings');
    } else if (currentPath.includes(ROUTES.Contact)) {
      setActiveMenu('contact');
    }
  }, [location.pathname]);

  return (
    <>
      <div className="w-full">
        <div className="grid grid-cols-12 h-full">
          {/* Logo Section */}
          <div className="col-span-12 md:col-span-4 bg-[#93CC36]">
            <div className="bg-[#397714] w-[60%] h-full border-[#93CC36] z-10 logo flex justify-end items-center rounded-tr-full rounded-br-full border">
              <HeaderLogo />
            </div>
          </div>

          {/* Menu Section */}
          <div className="col-span-12 md:col-span-8 bg-[#93CC36] relative">
            {/* Burger Icon for small screens */}
            <button
              className="text-[#27540C] text-3xl md:hidden p-4 relative z-40"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {/* Burger Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-10 h-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                />
              </svg>
            </button>

            {/* Menu Items */}
            <ul
              className={`md:flex p-2 font-semibold text-md text-[#27540C] transition-all duration-300 ease-in-out 
                ${
                  isMenuOpen
                    ? 'flex flex-col md:static absolute top-[4rem] z-30 bg-[#93CC36]'
                    : 'hidden'
                } 
                md:flex-row md:static md:bg-transparent md:space-x-4`}
            >
              <HandleClicker
                onClick={() => handleMenuClick('home')}
                to={`${ROUTES.Home}`}
              >
                <li className={`${activeMenuClass('home')} cursor-pointer`}>
                  Home
                </li>
              </HandleClicker>
              <HandleClicker
                onClick={() => handleMenuClick('about')}
                to={`${ROUTES.About}`}
              >
                <li className={`${activeMenuClass('about')} cursor-pointer`}>
                  About Us
                </li>
              </HandleClicker>
              <HandleClicker
                onClick={() => handleMenuClick('activities')}
                to={`${ROUTES.Activites}`}
              >
                <li
                  className={`${activeMenuClass('activities')} cursor-pointer`}
                >
                  Activities
                </li>
              </HandleClicker>
              <HandleClicker
                onClick={() => handleMenuClick('maps')}
                to={`${ROUTES.Seedlings}`}
              >
                <li
                  className={`${activeMenuClass('seedlings')} cursor-pointer`}
                >
                  Seedlings
                </li>
              </HandleClicker>
              <HandleClicker
                onClick={() => handleMenuClick('seedlings')}
                to={`${ROUTES.Contact}`}
              >
                <li className={`${activeMenuClass('contact')} cursor-pointer`}>
                  Contact Us
                </li>
              </HandleClicker>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
