export const Carousel5: React.FC = () => {
  return (
    <>
      <div className="w-full flex-shrink-0">
        <div className="grid grid-cols-8 p-8 w-[80%] mx-auto positive gap-2">
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="bg-[url('./image/taluto.jpeg')] bg-cover bg-center bg-no-repeat h-[200px] w-full rounded-tl-[30px] rou rounded:md"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Taluto
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Corypha utan</p>
              </div>
            </div>
          </div>
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="bg-[url('./image/maranglaparan.jpeg')] bg-cover bg-center bg-no-repeat h-[200px] w-full rounded-tl-[30px] rou rounded:md"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                White Luan
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Shorea contorta</p>
              </div>
            </div>
          </div>
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="bg-[url('./image/mangasinuro.jpeg')] bg-cover bg-center bg-no-repeat h-[200px] w-full rounded-tl-[30px] rou rounded:md"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Dugoan
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Pterocarpus santalinoides</p>
              </div>
            </div>
          </div>
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="bg-[url('./image/owayan.jpeg')] bg-cover bg-center bg-no-repeat h-[200px] w-full rounded-tl-[30px] rou rounded:md"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Ulayan
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Vitex parviflora</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
