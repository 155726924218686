interface NavProps {
  onClick: () => void;
  label: string;
}

export const NavigateButton: React.FC<NavProps> = ({ onClick, label }) => {
  return (
    <>
      <button
        className=" bg-[#93CC36] rounded-xl text-sm p-2 flex justify-start"
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          width="12px"
          height="12px"
          className="mr-2 mt-[4px] flex items-center"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
          />
        </svg>

        <label className="text-sm">{label}</label>
      </button>
    </>
  );
};
