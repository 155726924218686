export const Carousel1: React.FC = () => {
  return (
    <>
      <div className="w-full flex-shrink-0">
        <div className="grid grid-cols-8 p-8 w-[80%] mx-auto positive gap-2">
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="bg-[url('./image/catmon.jpeg')] bg-cover bg-center bg-no-repeat h-[200px] w-full rounded-tl-[30px] rou"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Katmon
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Dillenia philippinensis</p>
              </div>
            </div>
          </div>
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="bg-[url('./image/camagong.jpeg')] bg-cover bg-center bg-no-repeat h-[200px] w-full rounded-tl-[30px] rou rounded:md"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Marang Laparan
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Artocarpus odoratissimus</p>
              </div>
            </div>
          </div>
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="bg-[url('./image/ipil.jpeg')] bg-cover bg-center bg-no-repeat h-[200px] w-full rounded-tl-[30px] rou rounded:md"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Ipil
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Intsia bijuga</p>
              </div>
            </div>
          </div>
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="bg-[url('./image/gemsem.jpeg')] bg-cover bg-center bg-no-repeat h-[200px] w-full rounded-tl-[30px] rou"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Sagimsim
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Ficus copiosa</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
