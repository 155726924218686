export const Carousel4: React.FC = () => {
  return (
    <>
      <div className="w-full flex-shrink-0">
        <div className="grid grid-cols-8 p-8 w-[80%] mx-auto positive gap-2">
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="bg-[url('./image/arangin.jpeg')] bg-cover bg-center bg-no-repeat h-[200px] w-full rounded-tl-[30px] rou rounded:md"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Arangin
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Kleinhovia hospita</p>
              </div>
            </div>
          </div>
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="bg-[url('./image/bani.jpeg')] bg-cover bg-center bg-no-repeat h-[200px] w-full rounded-tl-[30px] rou rounded:md"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Bani
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Ficus nota</p>
              </div>
            </div>
          </div>
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="bg-[url('./image/bolangita.jpeg')] bg-cover bg-center bg-no-repeat h-[200px] w-full rounded-tl-[30px] rou rounded:md"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Bolong-eta
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Baringtonia asiatica</p>
              </div>
            </div>
          </div>
          <div className="col-span-8 md:col-span-2 border-b-2 rounded-b-md shadow-md">
            <div className="bg-[url('./image/tagisangbayawak.jpeg')] bg-cover bg-center bg-no-repeat h-[200px] w-full rounded-tl-[30px] rou rounded:md"></div>
            <div className="p-4">
              <label className="flex justify-center text-xl text-[#1C1704] font-semibold">
                Bitanghol
              </label>
              <div className="flex justify-center text-xs text-justify p-2">
                <p>Bucida molineti</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
