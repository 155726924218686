import { BgLogo } from 'src/components/logo/Bglogo';
import { CenroImage } from 'src/components/logo/CenroImage';

export const AboutContent = () => {
  return (
    <>
      <div>
        <div className="p-4 relative">
          <div className="activities">
            <div className="grid grid-cols-2 h-[668px] ">
              <div className="col-span-2 md:col-span-1  flex items-center justify-center  z-20">
                <BgLogo />
              </div>

              <div className="col-span-2 md:col-span-1 flex items-center justify-center p-6 text-white text-sm z-10">
                <p className="reveal-text">
                  <span className=" font-bold text-2xl  text-white">
                    About <span className="text-[#C0DF00]">us</span>
                  </span>
                  <br />
                  <br />
                  The simple act of planting a tree has the power to change the
                  world. It is a reminder that even the smallest gestures can
                  have a lasting impact. As the tree grows, so too does our
                  hope, our connection to nature, and our understanding of the
                  importance of nurturing the environment. By planting a tree,
                  we sow the seeds of a brighter, greener future.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="sec2 relative">
          <div className="container-sm p-12 mx-auto">
            <div className="p-4 text-justify font-light text-sm">
              <p>
                The project site was formerly part of IFMA 35 area of the then
                expired PICOP Resources Inc. It has an estimated area of 8.4
                hectares and located at Sitio Sanyata, San Roque, Bislig City,
                Surigao Del Sur. It is accessible by 2-wheel and 4-wheel
                vehicles. It has a distance of 14 kilometers from Brgy.
                Mangagoy, Bislig City, Surigao del Sur. The Arboretum area is
                predominantly grown with Dipterocarp species,
                furniture/construction hardwood species group and some
                miscellaneous species. After the expiration of IFMA 35 of PICOP
                Resources, Incorporated in 2007, the area was later on
                identified and set aside by PENRO Surigao del Sur as Arboretum
                Cum Ecotourism Site with tree tagging, tarpaulin signage and
                short trails introduced therein. Subsequently the management of
                the site was assumed by CENRO-Bislig last CY 2016 without
                funding allocation, hence no major developments were introduced
                except maintenance of the trails through brushing, forest
                protection and installation of tarpaulin signage. CENRO-Bislig
                visioned not just for promotion of forest and biodiversity
                conservation but also to somehow provide livelihood to the
                community with a Nature-based Community Entrepreneurship through
                vibrant ecotourism that is considered to be an important
                instrument to institute economic enterprise for social
                upliftment. The establishment of Arboretum and Rest and
                Recreation Area of CENRO Bislig in CY 2022 is one of the
                innovative efforts of this office in partnership with concerned
                Barangay and City LGU’s, to showcase forest conservation and
                forest land restoration strategy by incorporating the “Forest of
                Friendship” project where development is through social
                mobilization, tapping the academe, LGU’s, NGO’s and NGA’s for
                tree planting activities. Supporting this initiative, the DENR
                Regional Office thru RED Nonito M. Tamayo, CESO III, issued a
                Regional Special Order no. 493, Series of 2002, designating
                portion of public land located at Sitio Sanyata, San Roque,
                Bislig City, Surigao del Sur as “The Arboretum Cum Rest &
                Recreation Area of Bislig City”.
              </p>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="sec3img ">
            <div className="grid grid-cols-2 h-[668px] ">
              <div className="col-span-2 md:col-span-1 p-12 flex items-center justify-center  z-20">
                <div className="">
                  <p className="p-2  text-justify text-white text-sm">
                    <br />
                    The beauty of nature is a reflection of the beauty within
                    us.
                  </p>
                </div>
              </div>

              <CenroImage />
            </div>
          </div>
        </div>

        <div className="sec4 positive">
          <div className="container-sm p-4 mx-auto">
            <div className=" text-justify text-sm">
              <p>
                <div className="sec2">
                  <div className="container-sm p-12 mx-auto">
                    <div className="p-4 text-justify text-sm font-light">
                      <p>
                        Series of innovations, improvements and facilities were
                        then introduced through the innovative efforts of this
                        office under the able leadership of OIC, CENRO Nathaniel
                        E. Racho who assumed as OIC, CENRO of Bisligi in 2021.
                        The following activities enhancement/improvement and
                        innovations were facilitated.
                      </p>
                    </div>
                  </div>
                </div>
              </p>
            </div>
          </div>
        </div>

        <div className="sec5 positive">
          <div className="container-sm  mx-auto">
            <div className=" grid grid-cols-4 ml-16 mr-16 ">
              <div className="col-span-4 md:col-span-2">
                <p className="p-2  text-justify  text-sm mr-4 font-light">
                  <span className=" font-bold text-xl  ">
                    SITE VALIDATION AND PREPARATION
                  </span>
                  <br />
                  <br />
                  On February 23, 2022, OIC, CENR Officer Nathaniel E. Racho
                  headed the conduct of site validation and initial species
                  identification together with Park Maintenance Foreman (PMF)
                  Montano M. Raz in preparation for the establishment of the
                  Arboretum Rest and Recreation Area. Spots for establishment of
                  forest trails, nipa huts & identification of big & tallest
                  tree species to be declared as “King of Arboretum” were
                  facilitated.
                </p>
                <p className="p-2  text-justify  text-sm mr-4 font-light">
                  <span className=" font-bold text-xl  ">
                    IMPROVEMENT OF TRAILS
                  </span>
                  <br />
                  <br />
                  Gravelling of trails was undertaken to provide the visitors,
                  tourist and people with attractive, safe, accessible and low-
                  or no-cost places to walk, hike or jog and have a total
                  comfort while indulging in a forest walk or forest bathing
                  activity/es.
                </p>
                <p className="p-2  text-justify  text-sm mr-4 font-light">
                  <span className=" font-bold text-2xl  ">
                    <p className=" text-justify  text-sm mr-4">
                      <span className=" font-bold text-xl  ">TREE TAGGING</span>
                    </p>
                  </span>
                  Tree tagging was conducted by this office to facilitate easy
                  identification of trees by the visitors and tourist and for
                  appreciation of species diversity therein.
                </p>
                <p className="p-2  text-justify  text-sm mr-4 font-light">
                  <span className=" font-bold text-2xl  ">
                    <p className="  text-justify  text-sm mr-4">
                      <span className=" font-bold text-xl  ">
                        INSTALLATION OF SOLAR LIGHTS
                      </span>
                    </p>
                  </span>
                  Solar lights were installed purposely to provide practical
                  power source within the near community of the arboretum rest
                  and recreation area. Also, environment benefits of solar
                  lighting are considerable as well, drawing upon natural light,
                  solar power is an emission-free, infinitely renewable resource
                  that reduces one’s carbon footprint.
                </p>
              </div>
              <div className="col-span-4 md:col-span-2">
                <div className="denr"></div>
              </div>
            </div>
            <div className="p-4 text-justify text-sm ml-12 mr-12">
              <p className="text-xl font-bold ">
                INSTALLATION OF JEMATIC PUMP FOR WATER SUPPLY{' '}
              </p>
              <br />
              <p className="font-light text-justify">
                CENRO Nathaniel E. Racho also facilitated the installation of a
                deep-well hand water pump which is one of the most economical
                and simple solutions for providing a collective supply of water
                that can be used not only for watering the planted trees but
                also may help to suburban community near the arboretum for their
                needs. CENRO Nathaniel E. Racho also facilitated the
                installation of a deep-well hand water pump which is one of the
                most economical and simple solutions for providing a collective
                supply of water that can be used not only for watering the
                planted trees but also may help to suburban community near the
                arboretum for their needs. <br />
                <br />
                <p className="text-xl font-bold">
                  INSTALLATION OF JEMATIC PUMP FOR WATER SUPPLY{' '}
                </p>
                <br />
                For relaxation, chatting, eating/picnicking and enjoying the
                view of the area.
                <br />
                <br />
                <p className="text-xl font-bold">
                  INSTALLATION OF JEMATIC PUMP FOR WATER SUPPLY{' '}
                </p>
                <br />
                To have comfortable feeling when responding to the call of
                nature and promoting proper sanitation as well as to visitors
                and tourist visiting the area
              </p>
              <br />

              <div className="bg-[url('./image/col1.png')] bg-cover bg-center h-[551px] mt-4"></div>
              <div className="mt-4">
                <p className="font-light">
                  Step into the vibrant world of Bislig City Arboretum, a
                  dynamic haven in the heart of our city. Here, diverse plant
                  life converges in a colorful harmony, creating a serene escape
                  for nature enthusiasts, families, and botanists alike. Explore
                  scenic trails leading through various ecosystems, revealing
                  rare and endemic treasures. This living classroom goes beyond
                  visual delight, offering guided tours, workshops, and events
                  to deepen your understanding of plant life and conservation.
                  Capture the essence of nature in every frame amidst
                  picturesque landscapes, making every visit to the Bislig City
                  Arboretum a celebration of biodiversity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
