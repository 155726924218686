import React, { useState } from 'react';

interface Iprops {
  onCloseSlider: () => void;
}

export const Slider2: React.FC<Iprops> = ({ onCloseSlider }) => {
  const [currentSlide, setCurrentSlide] = useState(1);

  const goToNext = () => {
    setCurrentSlide((prev) => (prev === 6 ? 1 : prev + 1)); // Assume 3 slides for this example
  };

  const goToPrev = () => {
    setCurrentSlide((prev) => (prev === 1 ? 6 : prev - 1)); // Wrap around to last slide
  };

  return (
    <>
      <div className="w-full max-w-lg mx-auto h-full relative  ">
        <div
          onClick={onCloseSlider}
          className="text-red-500 font-bold text-3xl float-right  cursor-pointer"
        >
          X
        </div>
        <div className="relative w-full h-[450px] rounded-lg shadow-lg overflow-hidden">
          <button
            onClick={goToPrev}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-700 text-white rounded-full"
          >
            ◀
          </button>

          {/* Slide 1 */}
          {currentSlide === 1 && (
            <div className="bg-[url('./image/camping.jpg')] bg-cover bg-center h-full w-full"></div>
          )}

          {/* Slide 2 */}
          {currentSlide === 2 && (
            <div className="bg-[url('./image/c1.jpg')] bg-cover bg-center h-full w-full"></div>
          )}

          {/* Slide 3 */}
          {currentSlide === 3 && (
            <div className="bg-[url('./image/c2.jpg')] bg-cover bg-center h-full w-full"></div>
          )}
          {currentSlide === 4 && (
            <div className="bg-[url('./image/c3.jpg')] bg-cover bg-center h-full w-full"></div>
          )}
          {currentSlide === 5 && (
            <div className="bg-[url('./image/c4.jpg')] bg-cover bg-center h-full w-full"></div>
          )}
          {currentSlide === 6 && (
            <div className="bg-[url('./image/c5.jpg')] bg-cover bg-center h-full w-full"></div>
          )}

          <button
            onClick={goToNext}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-700 text-white rounded-full"
          >
            ▶
          </button>
        </div>
      </div>
    </>
  );
};
